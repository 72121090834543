interface USER_INFO {
    email: string,
    username: string,
    first_name: string | null,
    last_name: string | null,
    is_superuser: boolean,
}

export interface USER {
    isLoading: boolean,
    initialLoading: boolean,
    user: USER_INFO,
    isAuthenticated: boolean,
    token: string | null,
}

export const initialUserState: USER = {
    isLoading: false,
    initialLoading: false,
    user: {
        email: "NO auth",
        username: "NO auth",
        first_name:  "NO auth",
        last_name:  "NO auth",
        is_superuser: false,
    },
    isAuthenticated: false,
    token: localStorage.getItem("token"),
}

export const userReducer = (state: any, action: any) => {
    switch (action.type) {
        case "LOGIN":
            return {...state, isLoading: true};

        case "INITIAL_LOADING":
            return {...state, initialLoading: true};
    
        case "LOGIN_FAILED":
            return {...state, isLoading: false};

        case "LOGOUT":
            localStorage.removeItem("token");
            return {...initialUserState, isAuthenticated: false};

        case "LOGIN_SUCCESS":
            localStorage.setItem("token", action.payload.token);
            return {
                ...state,
                isLoading: false,
                initialLoading: false,
                user: action.payload.user,
                isAuthenticated:  true,
                token: localStorage.getItem("token"),
            };

        case "FETCH_USER_SUCCESS":
            return {
                ...state,
                isLoading: false,
                initialLoading: false,
                user: action.payload,
                isAuthenticated:  true,
                token: localStorage.getItem("token"),
            };
        
        default:
            return initialUserState;
    }
}

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AdminHome from "../../pages/Admin";
import AdminNav from "../../components/navs/AdminNav";
import AdminProtectedRoute from "../AdminProtectedRoute";
import PatientsList from "../../pages/Admin/PatientsList";
import PatientDetails from "../../pages/Admin/PatientDetails";

const AdminRouter: React.FC = () => {
  return (
    <Router>
      <div>
        <AdminNav />
        <Switch>
          <AdminProtectedRoute path="/admin/home" component={AdminHome} />
          <AdminProtectedRoute path="/admin/patients/:facId" component={PatientsList} />
          <AdminProtectedRoute path="/admin/patient-details/:patientId" component={PatientDetails} />
        </Switch>
      </div>
    </Router>
  );
}

export default AdminRouter;
import React, { useContext, useState } from 'react';
import { Button, Form, Loader, Message } from 'semantic-ui-react';
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";

import UserCont from "../../context/UserContext";
import { login } from "../../api/auth";

import RightImage from "../../img/doctors-pic.png";
import ProfileImage from "../../img/profile-pic.png";

const StyledForm = styled(Form)`
    width: 100vw;
    height: 100vh;
    display: flex;
`;

const LoginButton = styled(Button)`
  display: block !important;
  opacity: ${props => props.disabled ? ".5" : "1"}
`;

const StyledLable = styled.label`
  text-align: start;
  margin-bottom: 5px;
`;

const Helper = styled.div`
  text-align: end;
  opacity: 0.8;
`;

const Legend = styled.legend`
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 30px;
  color: #1d2366;
  margin-bottom: 50px;
`;



const LeftSide = {
  width: "50%",
};
const DoctorsImage = {
  width: "100%",
  height: "100%",
};

const RightSide = {
  width: "50%",
  paddingRight: "8%",
  paddingLeft: "8%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: 'column' as 'column'

}
const FaceImage = {
  width: "150px", 
  height: "150px"
};
const FormDiv = {
  width: "100%",
  marginBottom: "30px"
};
const FormInput = {
  width: "100%",
  background: "white",
  border: "2px solid #1d2366",
  height: "50px",
  fontSize: "20px"
};
const FormInputLabel = {
  fontSize: "20px",
  color: "#1d2366", 
  marginBottom: "15px", 
};
const LoginBtn = {
  width: "30%", 
  background: "#1d2366", 
  color: "white", 
  fontSize: "23px", 
  borderRadius: "30px", 
  padding: "20px 10px 20px 10px", 
  marginBottom: "10px"
};
const RegisterText = {
  color: "#1d2366", 
  fontSize: "16px",
  marginTop: "10px",
};
const RegisterBtn = {
  color: "#1d2366", 
};
const Loading = styled.div`
    font-weight: 700;
    text-transform: capitalize;
    height:70vh;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:40px;
    color: #1d2366;
`;
export default function SignIn() {
  const userContext = useContext(UserCont);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    userContext.dispatch({type: "LOGIN"})
    const values = {
      email_or_username: username,
      password,
    }
    const res = await login(values);
    if (res.success) {
      console.log(res);
      userContext.dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data,
      })
    } else {
      console.log(res);
      setError(true);
      setTimeout(() => setError(false), 5000);
      userContext.dispatch({
        type: "LOGIN_FAILED",
        payload: res,
      })
    }
  };

  if (userContext.state.isLoading) {
    return <Loading> Loading ... </Loading>
  } else if (userContext.state.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: userContext.state.user.is_superuser ? "/admin/home" : "/user/home",
        }}
      />
    )
  }
  return (
    <StyledForm onSubmit={handleSubmit}>
      <div style={LeftSide}>
        <img src={RightImage} style={DoctorsImage}/>
      </div>
      <div style={RightSide}>
      <img src={ProfileImage} style={FaceImage}/>
        <Legend> Welcome </Legend>
        {/* <div> email: {userContext.state.user.email} </div>
        <div> is authenticated: {userContext.state.isAuthenticated} </div> */}
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> Username or Email</StyledLable>
          <input style={FormInput} placeholder='Username or Email' type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </Form.Field>
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> Password </StyledLable>
          <input style={FormInput} placeholder='Passwored' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Field>
          <LoginButton style={LoginBtn} type='submit' className="ml-auto" disabled={userContext.state.isLoading}>  
            {userContext.state.isLoading ? "Loading..." : "Log In" } 
          </LoginButton>
          <Helper style={RegisterText}> 
            You Don't Have An Account? Register From 
            <Link to="register" style={RegisterBtn}> Here </Link>
          </Helper>
          {error &&
            <Message negative>
              <Message.Header> Invalid Credentials </Message.Header>
            </Message>
          }
        </div>
    </StyledForm>
  );
}
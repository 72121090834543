import React, { useContext, useState } from 'react';
import { Button, Form, Loader, Message } from 'semantic-ui-react';
import { Link, useHistory, Redirect } from "react-router-dom";
import styled from "styled-components";

import UserCont from "../../context/UserContext";
import { register } from "../../api/auth";

import RightImage from "../../img/doctors-pic.png"

const StyledForm = styled(Form)`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const LoginButton = styled(Button)`
  display: block !important;
  opacity: ${props => props.disabled ? ".5" : "1"}
`;

const StyledLable = styled.label`
  text-align: start;
  margin-bottom: 5px;
`;

const Helper = styled.div`
  text-align: end;
  opacity: 0.8;
`;

const Legend = styled.legend`
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
  margin-bottom: 30px;
`;

const LeftSide = {
  width: "50%",
};
const DoctorsImage = {
  width: "100%",
  height: "100%",
};

const RightSide = {
  width: "50%",
  paddingRight: "8%",
  paddingLeft: "8%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: 'column' as 'column'
}
const FormDiv = {
  width: "100%",
  marginBottom: "10px"
};

const FormInput = {
  width: "100%",
  background: "white",
  border: "2px solid #1d2366",
  height: "40px",
  fontSize: "16px"
};
const FormInputLabel = {
  fontSize: "20px",
  color: "#1d2366", 
  marginBottom: "15px", 
};
const LoginBtn = {
  width: "30%", 
  background: "#1d2366", 
  color: "white", 
  fontSize: "23px", 
  borderRadius: "30px", 
  padding: "20px 10px 20px 10px", 
  marginBottom: "10px",
  marginTop: "20px"
};
const RegisterText = {
  color: "#1d2366", 
  fontSize: "16px",
  marginTop: "10px",
};
const RegisterBtn = {
  color: "#1d2366", 
};
export default function Register() {
  const userContext = useContext(UserCont);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const validate = () => {
    if (!username) {
      setError("Username field is required!");
      setTimeout(() => setError(""), 2000);
      return false;
    }
    if (!email) {
      setError("Email field is required!");
      setTimeout(() => setError(""), 5000);
      return false;
    }
    if (!username) {
      setError("Username field is required!");
      setTimeout(() => setError(""), 5000);
      return false;
    }
    if (!firstName) {
      setError("First Name field is required!");
      setTimeout(() => setError(""), 5000);
      return false;
    }
    if (!lastName) {
      setError("Last Name field is required!");
      setTimeout(() => setError(""), 5000);
      return false;
    }
    if (!password) {
      setError("Password field is required!");
      setTimeout(() => setError(""), 5000);
      return false;
    }
    if (!password2) {
      setError("Repeat Password field is required!");
      setTimeout(() => setError(""), 5000);
      return false;
    }
    if (password !== password2) {
      setError("Passwords doesn't match!");
      setTimeout(() => setError(""), 5000);
      return false;
    }

    return true;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const isValid = validate();

    if (!isValid) {
      return;
    }

    const values = {
      email,
      username,
      password,
      first_name: firstName,
      last_name: lastName,
    };

    const res = await register(values);
    if (res.success) {
      setLoading(false);
      console.log(res);
      history.push("/");
    } else {
      setLoading(false);
      console.log({ res });
      if (res.data.response.status === 400) {
        let errorMessage = "";
        const responseData = res.data.response.data;
        for (const property in responseData) {
          errorMessage = errorMessage + responseData[property].join(", ")
        }
        setError(errorMessage);
      } else {
        setError("error occured");
      }
    }
  };
  if (userContext.state.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: userContext.state.user.is_superuser ? "/admin/home" : "/user/home",
        }}
      />
    )
  }
  return (
    <StyledForm onSubmit={handleSubmit}>
      <div style={LeftSide}>
        <img src={RightImage} style={DoctorsImage}/>
      </div>
      <div style={RightSide}>
        <Legend> Register </Legend>
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> Username </StyledLable>
          <input style={FormInput} placeholder='Username' type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </Form.Field>
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> Email </StyledLable>
          <input style={FormInput} placeholder='Email' type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Field>
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> First Name </StyledLable>
          <input style={FormInput} placeholder='First Name' type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </Form.Field>
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> Last Name </StyledLable>
          <input style={FormInput} placeholder='Last Name' type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </Form.Field>
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> Password </StyledLable>
          <input style={FormInput} placeholder='Passwored' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Field>
        <Form.Field required style={FormDiv}>
          <StyledLable style={FormInputLabel}> Repeat Password </StyledLable>
          <input style={FormInput} placeholder='Repeat Password' type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
        </Form.Field>
          <LoginButton style={LoginBtn} type='submit' className="ml-auto" disabled={userContext.state.isLoading}>
            {userContext.state.isLoading ? "Loading..." : "Register"} 
          </LoginButton>
        <Helper style={RegisterText}>
          You Already Have An Account? Login From
          <Link to="/" style={RegisterBtn}> Here </Link>
        </Helper>
        {error &&
          <Message negative>
            <Message.Header> {error} </Message.Header>
          </Message>
        }
      </div>
    </StyledForm>
  );
}
import React, { useEffect, useState } from "react";
import { fetchPatientDetails } from "../../../api/admin"
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const Legend = styled.legend`
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
  margin-bottom: 40px;
  width: 100%;
`;
const StyledPatient = styled.div`
    width: 40%;
    margin-left: 30%;
    border: 2px solid;
    border-radius: 20px;
    height: 75vh;
    background: #1d236608;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
`;
const PatientInfo = styled.div`
  display: flex;
  margin-left: 30px;
  font-size: 18px;
  color: #1d2366;
  margin-bottom: 10px;
`;
const PatientInfoText = styled.div`
    font-weight: 700;
    margin-right: 20px;
    text-transform: capitalize;
`;
const Loading = styled.div`
    font-weight: 700;
    text-transform: capitalize;
    height:70vh;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:40px;
    color: #1d2366;
`;

const Button = styled.div`
  width: 160px;
  background: rgb(109 98 252);
  color: white;
  font-size: 17px;
  border-radius: 30px;
  padding: 13px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  margin: auto;
  margin-top: 2rem;
`;

const AdminPatientDetails: React.FC = (props: any) => {
    const [patient, setPatient] = useState<{
        gender?: string,
        firstName?: string,
        lastName?: string,
        patientId?: number,
        admissionDateTime?: Date,
        birthDate?: Date,
        citizenship?: string,
        homePhone?: string,
        ituPhone?: string,
        maritalStatus?: string,
        medicalRecordNumber?: string,
        medicareNumber?: string,
        outpatient?: boolean,
        patientStatus?: string,
        religion?: string,
        
    }>({});
    const [loading, setLoading] = useState(false);
    let params = useParams<{ patientId: string }>();

    const getPatientDetails = async () => {
        setLoading(true);
        const res = await fetchPatientDetails(params.patientId);
        console.log({ res });
        setLoading(false);
        if (res.success) {
            setPatient(res.data);
        } 
    };
    console.log({ patient });

    useEffect(() => {
        getPatientDetails();
    }, []);

    return (
        <div>
            {!loading ?
                <div>
                    <Legend>Patient Details</Legend>
                    <StyledPatient>
                        <PatientInfo>
                            <PatientInfoText> id: </PatientInfoText>
                            {patient.patientId}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> firstName: </PatientInfoText>
                            {patient.firstName}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> lastName: </PatientInfoText>
                            {patient.lastName}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> gender: </PatientInfoText>
                            {patient.gender}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> admissionDateTime: </PatientInfoText>
                            {patient.admissionDateTime}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> birthDate: </PatientInfoText>
                            {patient.birthDate}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> citizenship: </PatientInfoText>
                            {patient.citizenship}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> homePhone: </PatientInfoText>
                            {patient.homePhone}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> ituPhone: </PatientInfoText>
                            {patient.ituPhone}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> maritalStatus: </PatientInfoText>
                            {patient.maritalStatus}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> medicalRecordNumber: </PatientInfoText>
                            {patient.medicalRecordNumber}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> medicareNumber: </PatientInfoText>
                            {patient.medicareNumber}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> outpatient: </PatientInfoText>
                            {patient.outpatient ? "Yes" : "No"}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> patientStatus: </PatientInfoText>
                            {patient.patientStatus}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> religion: </PatientInfoText>
                            {patient.religion}
                        </PatientInfo>
                    </StyledPatient>
                    <Button onClick={() => props.history.goBack()}>
                      Back
                    </Button>
                </div> : null}
            {loading ? <Loading> Loading... </Loading> : null}
        </div>
    );
}

export default withRouter(AdminPatientDetails);

import { rootURL } from "../index";
import axios  from "axios";

const fetchPatientsURL = `${rootURL}api/user/patients/`;
const fetchPatientURL = `${rootURL}api/patient/`;

export const getConfig = () => {
    return {
        headers: {
            Authorization: "Token " + localStorage.getItem("token"),
        } 
    }
};

export const fetchPatients = async ( page: number) => {
    const config = getConfig();
    return await axios.get(`${fetchPatientsURL}?page=${page}`, config).then(
        res => {
            return {
                data: res.data,
                success: true
            }
        },
        err => {
            return {
                data: err,
                success: false
            }
        },
    )
};

export const fetchPatientDetails = async (patientId: string) => {
    const config = getConfig();
    return await axios.get(`${fetchPatientURL}${patientId}/`, config).then(
        res => {
            return {
                data: res.data,
                success: true
            }
        },
        err => {
            return {
                data: err,
                success: false
            }
        },
    )
};
import React from "react";
import { initialUserState, USER } from "../reducers/auth";

const UserContext = React.createContext<{
    state: USER;
    dispatch: React.Dispatch<any>;
  }>({
      state: initialUserState,
      dispatch: () => null
  });
export default UserContext; 
import React, { useEffect, useState } from "react";
import { fetchPatients } from "../../../api/admin"
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Patient from "../../../img/patient.png"
const Patients = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: space-between;
  grid-row-gap: 30px;
  margin-right: 5%;
  margin-left: 5%;
`;
const StyledPatient = styled.div`
  border: 2px solid;
  border-radius: 20px;
  height: 300px;
  background: #1d236608;
`;

const Legend = styled.legend`
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
  margin-bottom: 40px;
  width: 100%;
`;
const DetailButton = styled.div`
  width: 160px;
  background: rgb(109 98 252);
  color: white;
  font-size: 17px;
  border-radius: 30px;
  padding: 13px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
const BottomDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PatientImage = styled.img`
  width: 40px;
  margin-right: 20px;
`;
const PatientId = styled.legend`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;
const PatientInfo = styled.div`
  display: flex;
  margin-left: 30px;
  font-size: 18px;
  color: #1d2366;
  margin-bottom: 10px;
`;
const PatientInfoText = styled.div`
  font-weight: 700;
  margin-right: 10px;
`;
const Loading = styled.div`
    font-weight: 700;
    text-transform: capitalize;
    height:70vh;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:40px;
    color: #1d2366;
`;
const NextButton = styled.div`
    height: 40px;
    width: 115px;
    background: rgb(29, 35, 102);
    color: white;
    font-size: 18px;
    border-radius: 30px;
    padding: 17px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
`;
const PreviousButton = styled.div`
    height: 40px;
    width: 115px;
    background: rgb(29, 35, 102);
    color: white;
    font-size: 18px;
    border-radius: 30px;
    padding: 17px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
`;
const NavigationButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AdminPatientsList: React.FC = () => {
  const [patients, setPatients] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  let params = useParams<{facId: string}>();

  const getPatientsList = async () => {  
    setLoading(true);
    const res = await fetchPatients(params.facId, page);
    console.log({res});
    setLoading(false);
    if (res.success) {
        setPatients(res.data.patients);
        if (res.data.has_more){
            const nextPage = page + 1;
            setLastPage(nextPage);
        }
    }
  };

  const getNextPage = () => {
    setPage(page+1);
  };

  const getPreviousPage = () => {
    setPage(page-1);
  };

  console.log({patients});
  useEffect(() => {
    getPatientsList();
  }, [page]);

  return (
      <div>
        <div> 
          {loading ? null: <Legend> Linked Patients </Legend>}
          <Patients>
            {!loading && patients.map(patient => {
              return (
                <StyledPatient key={patient.patientId}> 
                  <TopDiv>
                    <PatientImage src={Patient} />
                    <PatientId> Patient No {patient.patientId}</PatientId>
                  </TopDiv>
                  <div>
                    <PatientInfo>
                        <PatientInfoText> Name: </PatientInfoText>
                        {patient.firstName} {patient.lastName}
                    </PatientInfo>
                    <PatientInfo>
                        <PatientInfoText> Birth Date: </PatientInfoText>
                        {patient.birthDate}
                    </PatientInfo>
                    <PatientInfo>
                        <PatientInfoText> Gender: </PatientInfoText>
                        {patient.gender}
                    </PatientInfo>
                    <PatientInfo>
                        <PatientInfoText> Phone: </PatientInfoText>
                        {patient.ituPhone}
                    </PatientInfo>
                    <PatientInfo>
                        <PatientInfoText> Admission Date: </PatientInfoText>
                        {patient.admissionDate}
                    </PatientInfo>
                  </div>
                  <BottomDiv>
                    <Link to={`/admin/patient-details/${patient.patientId}`}>
                      <DetailButton>
                        More Details
                      </DetailButton>
                    </Link>
                  </BottomDiv>
                </StyledPatient>
              )
            })}
          </Patients>
        </div>
        {loading ? <Loading> Loading... </Loading> : null}
        {loading ? null: 
          <NavigationButtons>
            {page > 1 ? <PreviousButton onClick={getPreviousPage}> Previous </PreviousButton> : null}
            {lastPage > page ? <NextButton onClick={getNextPage}> Next </NextButton> : null}
          </NavigationButtons>
        }
        
        
      </div>
  );
}

export default AdminPatientsList;

import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import UserCont from "../../context/UserContext";

const UserProtectedRoute = ({component, ...rest}: any) => {
    const userContext = useContext(UserCont);

    const routeComponent = (props: any) => (
        userContext.state.isLoading ?
        <div> Loading... </div>
        : userContext.state.isAuthenticated && !userContext.state.user.is_superuser
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/login'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

export default UserProtectedRoute;
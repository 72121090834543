import React, { useEffect, useState } from "react";
import { fetchPatientDetails, fetchUsers, linkPatient } from "../../../api/admin"
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import User from "../../../img/user.png";
import { withRouter } from 'react-router-dom';

const Legend = styled.legend`
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
  margin-bottom: 40px;
  width: 100%;
`;
const StyledPatient = styled.div`
    width: 40%;
    margin-left: 30%;
    border: 2px solid;
    border-radius: 20px;
    height: 75vh;
    background: #1d236608;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding-left: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 35px;
`;
const PatientInfo = styled.div`
  display: flex;
  margin-left: 30px;
  font-size: 18px;
  color: #1d2366;
  margin-bottom: 10px;
`;
const PatientInfoText = styled.div`
    font-weight: 700;
    margin-right: 20px;
    text-transform: capitalize;
`;

const Users = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: space-between;
  margin-right: 5%;
  margin-left: 5%;
`;
const StyledUser = styled.div`
  border: 2px solid;
  border-radius: 20px;
  height: 245px;
  background: #1d236608;
  margin-bottom: 30px;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;
const UserImage = styled.img`
  width: 40px;
  margin-right: 20px;
`;
const UserId = styled.legend`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
`;

const UserInfo = styled.div`
  display: flex;
  margin-left: 30px;
  font-size: 18px;
  color: #1d2366;
  margin-bottom: 10px;
`;
const UserInfoText = styled.div`
  font-weight: 700;
  margin-right: 10px;
`;
const BottomDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AlreadyButton = styled.div`
  width: 160px;
  background: rgb(29 35 102);
  color: white;
  font-size: 17px;
  border-radius: 30px;
  padding: 13px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
const LinkButton = styled.div`
  width: 160px;
  background: rgb(109 98 252);
  color: white;
  font-size: 17px;
  border-radius: 30px;
  padding: 13px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
const Loading = styled.div`
    font-weight: 700;
    text-transform: capitalize;
    height:70vh;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:40px;
    color: #1d2366;
`;

const Button = styled.div`
  width: 160px;
  background: rgb(109 98 252);
  color: white;
  font-size: 17px;
  border-radius: 30px;
  padding: 13px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const AdminPatientDetails: React.FC = (props: any) => {
    const [patient, setPatient] = useState<{
        gender?: string,
        firstName?: string,
        lastName?: string,
        patientId?: number,
        admissionDateTime?: Date,
        birthDate?: Date,
        citizenship?: string,
        homePhone?: string,
        ituPhone?: string,
        maritalStatus?: string,
        medicalRecordNumber?: string,
        medicareNumber?: string,
        outpatient?: boolean,
        patientStatus?: string,
        religion?: string,
    }>({});
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [beingModifiedUser, setBeingModifiedUser] = useState(0);
    let params = useParams<{ patientId: string }>();

    const getPatientDetails = async () => {
        setLoading(true);
        const res = await fetchPatientDetails(params.patientId);
        console.log({ res });
        setLoading(false);
        if (res.success) {
            setPatient(res.data);
        }
    };

    const getUsersList = async () => {
        setLoadingUsers(true);
        const res = await fetchUsers();
        console.log({ res });
        setLoadingUsers(false);
        if (res.success) {
            setUsers(res.data);
        }
    };

    const linkPatientToUser = async (user: any) => {
        const data = {
            patient_id: patient.patientId,
            user: user.id,
            first_name: patient.firstName,
            last_name: patient.lastName,
            gender: patient.gender
        }
        setBeingModifiedUser(user.id);
        const res = await linkPatient(data);
        setBeingModifiedUser(0);
        if (res.success) {
            const newUsers = users.map(user => {
                if (user.id === res.data.id)
                    return res.data;
                else
                    return user;
            });
            setUsers(newUsers);
        };
    };

    const patientIsLinked = (user: any) => {
        for (const linkedPatient of user.linked_patients) {
            if (linkedPatient.patient_id === patient.patientId)
                return true;
        }
        return false;
    };

    console.log({ patient });

    useEffect(() => {
        getPatientDetails();
        getUsersList();
    }, []);

    return (
        <div>
            {!loading && !loadingUsers ?
                (<div>
                    <Legend>Patient Details</Legend>
                    <StyledPatient>
                        <PatientInfo>
                            <PatientInfoText> id: </PatientInfoText>
                            {patient.patientId}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> firstName: </PatientInfoText>
                            {patient.firstName}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> lastName: </PatientInfoText>
                            {patient.lastName}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> gender: </PatientInfoText>
                            {patient.gender}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> admissionDateTime: </PatientInfoText>
                            {patient.admissionDateTime}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> birthDate: </PatientInfoText>
                            {patient.birthDate}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> citizenship: </PatientInfoText>
                            {patient.citizenship}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> homePhone: </PatientInfoText>
                            {patient.homePhone}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> ituPhone: </PatientInfoText>
                            {patient.ituPhone}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> maritalStatus: </PatientInfoText>
                            {patient.maritalStatus}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> medicalRecordNumber: </PatientInfoText>
                            {patient.medicalRecordNumber}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> medicareNumber: </PatientInfoText>
                            {patient.medicareNumber}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> outpatient: </PatientInfoText>
                            {patient.outpatient ? "Yes" : "No"}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> patientStatus: </PatientInfoText>
                            {patient.patientStatus}
                        </PatientInfo>
                        <PatientInfo>
                            <PatientInfoText> religion: </PatientInfoText>
                            {patient.religion}
                        </PatientInfo>
                    </StyledPatient>
                </div>)
                : null}
            {!loading && !loadingUsers ?
                <Legend>List of users</Legend>
                : null}
            <Users>
                {!loading && !loadingUsers && users.map(user => {
                    return (
                        <StyledUser key={user.id}>
                            <TopDiv>
                                <UserImage src={User} />
                                <UserId> User No {user.id}</UserId>
                            </TopDiv>
                            <div>
                                <UserInfo>
                                    <UserInfoText> Username: </UserInfoText>
                                    {user.username}
                                </UserInfo>
                                <UserInfo>
                                    <UserInfoText> Name: </UserInfoText>
                                    {user.first_name} {user.last_name}
                                </UserInfo>
                                <UserInfo>
                                    <UserInfoText> Email: </UserInfoText>
                                    {user.email}
                                </UserInfo>
                            </div>
                            <BottomDiv>
                                {patientIsLinked(user) ?
                                    <AlreadyButton>
                                        Already Linked
                                    </AlreadyButton>
                                    :
                                    <LinkButton onClick={() => linkPatientToUser(user)}>
                                        Link
                                    </LinkButton>
                                }
                            </BottomDiv>
                        </StyledUser>
                    )
                })}
            </Users>
            <Button onClick={() => props.history.goBack()}>
                Back
            </Button>
            {loading || loadingUsers ? <Loading> Loading ... </Loading> : null}
        </div>
    );
}

export default withRouter(AdminPatientDetails);

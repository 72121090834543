import React, {useContext} from 'react';

import { logout } from "../../../api/auth";
import UserCont from "../../../context/UserContext";
import { Button} from 'semantic-ui-react';
import styled from "styled-components";
const LogoutButton = styled(Button)`
  display: block !important;
`;
const LogoutBtn = {
    width: "8%", 
    background: "#1d2366", 
    color: "white", 
    fontSize: "18px", 
    borderRadius: "30px", 
    padding: "17px 10px 17px 10px", 
    marginBottom: "10px",
    marginTop: "10px",
    marginRight: "10px",
  };

const NavBar = {
    width: "100%", 
    display: "flex",
    justifyContent: "flex-end",
  };

export default function UserNav() {
    const userContext = useContext(UserCont);

    const onLogoutClick = async () => {
        const response = await logout();
        if (response.success) {
            userContext.dispatch({type: "LOGOUT"})
        }
    };

    return (
        <nav style={NavBar}>
            <LogoutButton style={LogoutBtn} onClick={onLogoutClick}> Logout </LogoutButton>    
        </nav>
    )
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { fetchPatients } from "../../api/user"
import Patient from "../../img/patient.png"
const Patients = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
  grid-row-gap: 30px;
  margin-right: 5%;
  margin-left: 5%;
`;
const StyledPatient = styled.div`
  border: 2px solid;
  border-radius: 20px;
  height: 220px;
  background: #1d236608;
`;

const Legend = styled.legend`
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
  margin-bottom: 40px;
  width: 100%;
`;
const DetailButton = styled.div`
  width: 160px;
  background: rgb(109 98 252);
  color: white;
  font-size: 17px;
  border-radius: 30px;
  padding: 13px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
const BottomDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PatientImage = styled.img`
  width: 40px;
  margin-right: 20px;
`;
const PatientId = styled.legend`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;
const PatientInfo = styled.div`
  display: flex;
  margin-left: 30px;
  font-size: 18px;
  color: #1d2366;
  margin-bottom: 10px;
`;
const PatientInfoText = styled.div`
  font-weight: 700;
  margin-right: 10px;
`;
const Loading = styled.div`
    font-weight: 700;
    text-transform: capitalize;
    height:70vh;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:40px;
    color: #1d2366;
`;
const NextButton = styled.div`
    height: 40px;
    width: 115px;
    background: rgb(29, 35, 102);
    color: white;
    font-size: 18px;
    border-radius: 30px;
    padding: 17px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
`;
const PreviousButton = styled.div`
    height: 40px;
    width: 115px;
    background: rgb(29, 35, 102);
    color: white;
    font-size: 18px;
    border-radius: 30px;
    padding: 17px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
`;
const NavigationButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;


const UserHome: React.FC = () => {
  const [patients, setPatients] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(1);

  const getPatientsList = async () => {  
    setLoading(true);
    const res = await fetchPatients(page);
    console.log({res});
    setLoading(false);
    if (res.success) {
        setPatients(res.data);
        if (res.data.next){
            const nextPage = page + 1;
            setLastPage(nextPage);
        }
    }
  };

  const getNextPage = () => {
    setPage(page+1);
  };

  const getPreviousPage = () => {
    setPage(page-1);
  };

  console.log({patients});
  useEffect(() => {
    getPatientsList();
  }, [page]);

  return (
      <div>
        <div> 
        <Legend> Linked Patients </Legend>
        <Patients>
          {!loading && patients.map(patient => {
            return (
              <StyledPatient key={patient.patient_id}> 
                <TopDiv>
                  <PatientImage src={Patient} />
                  <PatientId> Patient No {patient.patient_id}</PatientId>
                </TopDiv>
                <div>
                  <PatientInfo>
                      <PatientInfoText> Name: </PatientInfoText>
                      {patient.first_name} {patient.last_name}
                  </PatientInfo>
                  <PatientInfo>
                      <PatientInfoText> Gender: </PatientInfoText>
                      {patient.gender}
                  </PatientInfo>
                </div>
                <BottomDiv>
                  <Link to={`/user/patient-details/${patient.patient_id}`}>
                    <DetailButton>
                      More Details
                    </DetailButton>
                  </Link>
                </BottomDiv>
              </StyledPatient>
            )
          })}
        </Patients>
        </div>
        {loading ? <Loading> Loading ... </Loading> : null}
        <NavigationButtons>
          {page > 1 ? <PreviousButton onClick={getPreviousPage}> Previous </PreviousButton> : null}
          {lastPage > page ? <NextButton onClick={getNextPage}> Next </NextButton> : null}
        </NavigationButtons>
      </div>
  );
}

export default UserHome;


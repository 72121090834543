import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Register from "../pages/Register";
import Login from "../pages/Login";
import AdminRoute from "./Admin";
import UserRoute from "./User";
import UserProtectedRoute from "./UserProtectedRoute";
import AdminProtectedRoute from "./AdminProtectedRoute";

const Routers: React.FC = () => {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route                path="/register" component={Register} />
          <AdminProtectedRoute  path="/admin"    component={AdminRoute} />
          <UserProtectedRoute   path="/user"     component={UserRoute} />
          <Route                path="/"         component={Login} />
        </Switch>
      </div>
    </Router>
  );
}

export default Routers;
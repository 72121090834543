import { useReducer, useEffect } from "react";

import Routers from "./routers";
import UserContext from "./context/UserContext";
import { initialUserState, userReducer } from "./reducers/auth";
import { getUserInfo } from "./api/auth";

import './App.css';
import 'semantic-ui-css/semantic.min.css'

interface ContextType {
  state: object;
  dispatch: React.Dispatch<{ type: string; value: unknown }>;
}

function App() {
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  async function fetchUser() {
    userDispatch({type: "INITIAL_LOADING"});
    const response = await getUserInfo();
    
    console.log({response});

    if (response.success) {
      userDispatch({type: "FETCH_USER_SUCCESS", payload: response.data});
    } else {
      userDispatch({type: "LOGOUT"});
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) 
      fetchUser();
  }, []);

  console.log("loading: ", userState.initialLoading);

  if (userState.initialLoading) {
    return <div> Loading... </div>
  }
  return (
    <div className="App">
      <UserContext.Provider value={{ state: userState, dispatch: userDispatch }} >
        <Routers />
      </UserContext.Provider>
    </div>
  );
}

export default App;

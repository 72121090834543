import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import UserHome from "../../pages/User";
import UserNav from "../../components/navs/UserNav";
import UserProtectedRoute from "../UserProtectedRoute";
import UserPatientDetails from "../../pages/User/PatientDetails";

const UserRoute: React.FC = () => {
  return (
    <Router>
      <div>
        <UserNav />
        <Switch>
          <UserProtectedRoute path="/user/home" component={UserHome} />
          <UserProtectedRoute path="/user/patient-details/:patientId" component={UserPatientDetails} />
        </Switch>
      </div>
    </Router>
  );
}

export default UserRoute;
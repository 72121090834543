import { rootURL } from "../index";
import axios  from "axios";

const loginURL = `${rootURL}api/login/`;
const registerURL = `${rootURL}api/register/`;
const userInfoURL = `${rootURL}api/user/info/`;
const logOutURL = `${rootURL}api/logout/`;

export const getConfig = () => {
    return {
        headers: {
            Authorization: "Token " + localStorage.getItem("token"),
        } 
    }
};

export const login = async (data: any) => {
    return await axios.post(loginURL, data).then(
        res => {
            return {
                data: res.data,
                success: true
            }
        },
        err => {
            return {
                data: err,
                success: false
            }
        },
    ) 
};

export const register = async (data: any) => {
    return await axios.post(registerURL, data).then(
        res => {
            return {
                data: res.data,
                success: true
            }
        },
        err => {
            return {
                data: err,
                success: false
            }
        },
    ) 
};

export const logout = async () => {
    const config = getConfig();
    return await axios.post(logOutURL, null, config).then(
        res => {
            return {
                data: res.data,
                success: true
            }
        },
        err => {
            return {
                data: err,
                success: false
            }
        },
    ) 
};

export const getUserInfo = async () => {
    const config = getConfig();
    return await axios.get(userInfoURL, config).then(
        res => {
            return {
                data: res.data,
                success: true
            }
        },
        err => {
            return {
                data: err,
                success: false
            }
        },
    )
};
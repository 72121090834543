import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { fetchFicilities } from "../../api/admin"
import Building from "../../img/building.png"
const Facilities = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: space-between;
  grid-row-gap: 30px;
  margin-right: 5%;
  margin-left: 5%;
`;
const StyledFacility = styled.div`
  border: 2px solid;
  border-radius: 20px;
  height: 300px;
  background: #1d236608;
`;

const Legend = styled.legend`
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
  margin-bottom: 40px;
  width: 100%;
`;
const BuildingImage = styled.img`
  width: 40px;
  margin-right: 20px;
`;
const FacilityId = styled.legend`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  color: #1d2366;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
`;
const FacilityInfo = styled.div`
  display: flex;
  margin-left: 30px;
  font-size: 18px;
  color: #1d2366;
  margin-bottom: 10px;
`;
const FacilityInfoText = styled.div`
  font-weight: 700;
  margin-right: 10px;
`;
const DetailButton = styled.div`
  width: 160px;
  background: rgb(109 98 252);
  color: white;
  font-size: 17px;
  border-radius: 30px;
  padding: 13px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
const BottomDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;



const AdminHome: React.FC = () => {
  const [facilities, setFacilities] = useState<any[]>([]);
  const getFacilitiesList = async () => {
    const res = await fetchFicilities();
    if (res.success) {
      setFacilities(res.data.facilities);
    }
  };
  console.log({facilities});
  useEffect(() => {
    getFacilitiesList();
  }, []);

  return (
      <div>
        <div >
          <Legend> Facilities </Legend>
        </div>
        <Facilities>
          {facilities.map(facility => {
            return (
              <StyledFacility key={facility.facId}> 
                <TopDiv>
                  <BuildingImage src={Building} />
                  <FacilityId> Facility No {facility.facId}</FacilityId>
                </TopDiv>
                <div>
                  <FacilityInfo>
                      <FacilityInfoText> Name: </FacilityInfoText>
                      {facility.facilityName}
                  </FacilityInfo>
                  <FacilityInfo>
                      <FacilityInfoText> Code: </FacilityInfoText>
                      {facility.facilityCode}
                  </FacilityInfo>
                  <FacilityInfo>
                      <FacilityInfoText>Health Type: </FacilityInfoText>
                      {facility.healthType}
                  </FacilityInfo>
                  <FacilityInfo>
                      <FacilityInfoText>Phone: </FacilityInfoText>
                      {facility.phone}
                  </FacilityInfo>
                  <FacilityInfo>
                      <FacilityInfoText>City/State/Country: </FacilityInfoText>
                      {facility.city} {facility.state} {facility.country}
                  </FacilityInfo>
                </div>
                <BottomDiv>
                  <Link to={`/admin/patients/${facility.facId}/`}>
                    <DetailButton>
                      View Patients
                    </DetailButton>
                  </Link>
                </BottomDiv>
              </StyledFacility>
            )
          })}
        </Facilities>
      </div>
  );
}

export default AdminHome;
